import { ContentManager } from "./contentManager";

export type DayImport = () => Promise<{ manager: ContentManager }>;

export class DayManager {
    private days: { [day: string]: { title: string; fn: DayImport } } = {};

    public get keys() {
        return Object.keys(this.days);
    }

    public get length() {
        return Object.keys(this.days).length;
    }

    public get titles() {
        return Object.values(this.days).map(d => d.title);
    }

    public add(number: number | string, fn: DayImport, title: string) {
        this.days[number.toString()] = {
            fn,
            title
        };
    }

    public async get(day: string | number) {
        const i = await this.days[day.toString()].fn();
        return i.manager;
    }

    public getManagerLoader(day: string | number) {
        if(!day) return null;
        return this.days[day.toString()].fn;
    }

    public async dayOf(index: number) {
        return Object.keys(this.days)[index];
    }

    public title(day: string | number) {
        return this.days[day.toString()].title;
    }
}

export const manager = new DayManager();

manager.add(1, () => import("@/assets/days/day1"), "Introducing breast cancer, mortality, and incidence");
manager.add(2, () => import("@/assets/days/day2"), "Introducing mammogram");
manager.add(3, () => import("@/assets/days/day3"), "Tailoring: Knowledge day");
manager.add(4, () => import("@/assets/days/day4"), "Tailoring: Accessibility Day");
manager.add(5, () => import("@/assets/days/day5"), "Tailoring: Culture day");
manager.add(6, () => import("@/assets/days/day6"), "Tailoring: Clinic day");
manager.add(7, () => import("@/assets/days/day7"), "Summary");

export default manager;
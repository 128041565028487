


































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { mdiClose, mdiCheck } from "@mdi/js";
import manager from "@/services/dayManager";
import { Day } from "@/models/day";
import { db } from "@/plugins/firebase";
import { collection, onSnapshot, doc, setDoc } from "firebase/firestore";
import { Unsubscribe } from "@firebase/auth";

@Component({
    name: "DayDashboard"
})
export default class DayDashboard extends Vue {
    @Prop()
    private readonly active?: Day;

    private readonly manager = manager;

    private unsub: Unsubscribe | null = null;

    private days: Day[] = [];

    private loading = false;

    private readonly icons = {
        mdiClose,
        mdiCheck
    }

    private get uid() {
        return this.$store.state.user.uid;
    }

    async start(day: string) {
        setDoc(doc(db, "users", this.uid), {
            active: doc(db, "users", this.uid, "days", day)
        }, { merge: true });
        this.$router.push({ name: "Course" });
    }

    resume() {
        this.$router.push({ name: "Course" });
    }

    @Watch("uid", { immediate: true })
    async onUserChange(id: string) {
        if (this.unsub)
            this.unsub();
        this.loading = true;
        const q = collection(db, "users", id, "days");

        this.unsub = onSnapshot(q, docs => {
            docs.docChanges().forEach(c => {
                if (c.type === "added") {
                    this.days.push({ id: c.doc.id, ...c.doc.data() } as Day);
                }
                else if (c.type === "modified") {
                    const idx = this.days.findIndex(d => d.id === c.doc.id);
                    this.days[idx] = { id: c.doc.id, ...c.doc.data() } as Day;
                }
                else {
                    this.days = this.days.filter(d => d.id !== c.doc.id);
                }
            });
            this.loading = false;
        });
    }

    prevDone(day: Day) {
        const idx = this.days.indexOf(day);
        if (idx === 0) {
            return true;
        }
        return this.days[idx - 1].done;
    }

    getTitle(day: Day) {
        return this.manager.title(day.id);
    }
}
